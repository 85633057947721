<template>
    <div class="updateLog">
        <div class="titles">
            <span>更新日志</span>
            <span>
                <el-button
                    @click="onClose()"
                    size="mini"
                    type="primary"
                    icon="el-icon-arrow-left"
                    style="background:#fff;color:#2370EB;border-color:#2370EB"
                    >返回列表</el-button
                ></span
            >
        </div>
        <div class="content">
            <div>
                <p class="title">2022.01.26</p>
                <p class="text">
                    1.增加了工单模块，AE可提交工单到售后人员处理问题；
                </p>
            </div>
            <div>
                <p class="title">2022.01.14</p>
                <p class="text">
                    1.优化了列表信息展示和一些交互；
                </p>
                <p class="text">
                    2.修复了一些bug；
                </p>
            </div>
            <div>
                <p class="title">2022.01.06</p>
                <p class="text">
                    1.调整线索与客户逻辑，线索需要由客户为主体创建；
                </p>
                <p class="text">
                    2.SDR、MDR现可与AE关联，关联后SDR、MDR的线索将自动共享给AE；
                </p>
                <p class="text">
                    3.新建合同时可绑定客户的一条线索作为来源标识；
                </p>
                <p class="text">
                    4.资料信息现可进行清洗，获取公司主体信息及域名备案信息；
                </p>
            </div>
            <div>
                <p class="title">2021.12.15</p>
                <p class="text">
                    1.AE、AM“已成交客户”替换为“已成交订单”，可根据客户及产品分类查看腾讯企业邮箱信息；
                </p>
                <p class="text">
                    2.工作台拆分统计模块，移至数据看板页面；
                </p>
                <p class="text">
                    3.延长、放弃，报备、试用的审核消息可在快捷操作中审核；
                </p>
            </div>
            <div>
                <p class="title">2021.12.03</p>
                <p class="text">
                    1.优化经理角色相关功能，SDR、MDR的信息整合入“线索”菜单中；
                </p>
                <p class="text">
                    2.增加经理角色的待处理信息看板；
                </p>
                <p class="text">
                    3.word格式的附件现支持预览；
                </p>
                <p class="text">
                    4.修复了一些使用中的bug；
                </p>
            </div>
            <div>
                <p class="title">2021.11.25</p>
                <p class="text">
                    1.线索关联已有客户时，会将新的联系人信息添加到该客户中；
                </p>
                <p class="text">
                    2.合同附件现可上传多个；
                </p>
                <p class="text">
                    3.优化客户详情-联系人的展示，现会将过长的联系人内容折叠；
                </p>
            </div>
            <div>
                <p class="title">2021.11.23</p>
                <p class="text">
                    1.修复阿里邮箱无法试用的问题；
                </p>
                <p class="text">
                    2.优化直接报备功能，现在报备时不需要选择新客户老客户，提交申请时若是新客户则自动创建客户并报备；
                </p>
                <p class="text">
                    3.修复AE合同列表，时间查询有误的问题；
                </p>
            </div>
            <div>
                <p class="title">2021.11.19</p>
                <p class="text">
                    1.AE的资料库、客户列表、邮箱操作、合同列表增加统计；
                </p>
                <p class="text">
                    2.AE菜单调整，将客户操作合并入邮箱操作；将业绩申报合并入合同列表；隐藏试用列表；
                </p>
                <p class="text">
                    3.调整客户操作，现可通过“直接报备”按钮发起报备（未录入的客户也可直接报备）；
                </p>
                <p class="text">4.AE增加客户延期、放弃申请；</p>
                <p class="text">
                    5.业务助理菜单增加延期、放弃审核；可主动放弃报备、试用；
                </p>
                <p class="text">6.优化报备、试用历史；</p>
            </div>
            <div>
                <p class="title">2021.11.05</p>
                <p class="text">
                    1.SDR、MDR新增功能：删除线索及意向客户；
                </p>
                <p class="text">2.MDR新增功能：退回SDR分配的意向客户；</p>
                <p class="text">3.其他问题修复；</p>
            </div>
            <div>
                <p class="title">2021.11.02</p>
                <p class="text">
                    1.AE的客户详情中，增加关联线索的详情查看；
                </p>
                <p class="text">2.增加企业微信的产品交付消息通知；</p>
                <p class="text">3.其他调整及bug修复；</p>
            </div>
            <div>
                <p class="title">2021.10.29</p>
                <p class="text">
                    1.列表展示优化，现在列表中的操作按钮会固定显示在列表右侧；
                </p>
                <p class="text">2.消息通知优化，分配线索时将会展示更多的线索信息；</p>
            </div>
            <div>
                <p class="title">2021.10.28</p>
                <p class="text">
                    1.AE菜单增加“线索来源人”字段，可根据客户的线索来源人（SDR、MDR）查询客户；
                </p>
                <p class="text">2.会话存档产品的订单字段修改；</p>
            </div>
            <div>
                <p class="title">2021.10.27</p>
                <p class="text">
                    1.系统中的详情信息将以侧边窗口的形式弹出（保留查询条件及页数）；
                </p>
                <p class="text">2.资料库中增加星标功能，标星的资料将会往前排列；</p>
                <p class="text">3.线索、意向客户、客户详情中新增“联系备注”字段；</p>
            </div>
            <div>
                <p class="title">2021.10.21</p>
                <p class="text">
                    1.增加了SDR、MDR、AE角色的个人资料库及经理角色的部门资料库功能，可点击“资料库”-“个人资料库”以使用
                </p>
                <p class="text">
                    2.增加SDR的客户协作功能，可点击“客户管理”-“协作历史”以使用
                </p>
            </div>
            <div>
                <p class="title">2021.10.14</p>
                <p class="text">1.AE退回线索增加退回备注，将会通知线索的创建人</p>
                <p class="text">2.增加更新日志功能</p>
                <p class="text">3.合同审核的备注将会以企微消息通知合同的拥有者</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
    methods: {
        onClose() {
            window.history.go(-1);
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.updateLog {
    width: 100%;
    height: 100%;
    background: #fff;
    overflow: auto;
    display: flex;
    flex-direction: column;
    .titles {
        padding-top: 20px;
        padding-bottom: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-right: 20px;
    }
    .content {
        flex: 1;
        overflow: auto;
        padding-top: 0;
    }
    div {
        margin-left: 20px;
    }
    border-radius: 10px 10px 0 0;
    .title {
        font-size: 18px;
        color: #000;
        padding: 20px 0;
    }
    .text {
        font-size: 14px;
        color: #333;
        line-height: 30px;
    }
}
</style>
